import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};
export default {
  //userslogin
  LoginUser(payload) {
    return Api().post('/v2/login', payload);
  },
  logoutUser(payload) {
    return Api().post('/v2/logout');
  },
  UserList(filters, sort) {
    // console.log(filters, "fsdfds", filters.hasOwnProperty('contracy_active'));

    if (filters && filters.hasOwnProperty('contracy_active') && filters.hasOwnProperty('page')) {
      var contract_val = filters.hasOwnProperty('archive') ? 4 : 1;
      return Api().get(`/v2/users/page/${filters.page}?organization_id=${organization_id()}&contract_active=${contract_val}`);
    } else if (filters && filters.hasOwnProperty('contracy_active')) {
      var contract_val = filters.hasOwnProperty('archive') ? 4 : 1;

      return Api().get(`/v2/users?organization_id=${organization_id()}&contract_active=${contract_val}`);
    } else if (filters && filters.hasOwnProperty('archive') && filters.archive) {
      return Api().get(`/v2/users?archive=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/users/page/${filters.page}?organization_id=${organization_id()}`);
    } else return Api().get(`/v2/users?organization_id=${organization_id()}`);
  },
  CompanyUsersList(id) {

    return Api().get(`/v2/users/company/${id}?organization_id=${organization_id()}`);
  },
  AddUser(payload) {

    return Api().post(`/v2/users?organization_id=${organization_id()}`, payload);
  },
  EditUser(payload, id) {

    return Api().put(`/v2/users/${id}?organization_id=${organization_id()}`, payload);
  },
  getSingleUser(id, archive) {

    if (!archive) {
      return Api().get(`/v2/users/${id}?organization_id=${organization_id()}&contract_active=1`);
    } else {
      return Api().get(`/v2/users/${id}?${archive}&organization_id=${organization_id()}&contract_active=1`);
    }
  },
  getDepartmentByCompany(id) {
    return Api().get(`/v2/department/company/${id}?organization_id=${organization_id()}`)
  },
  getDesignationByCompany(id) {
    return Api().get(`/v2/designation/company/${id}?organization_id=${organization_id()}`)
  },
  remainingUsers(id) {
    return Api().get(`/v2/project/users/${id}?organization_id=${organization_id()}`);
  },
  getProfileInfo() {

    return Api().get(`/v2/getprofileinfo?organization_id=${organization_id()}`);
  },
  getProfileInfoViaOrg(organizationid) {
    return Api().get(`/v2/getprofileinfo?organization_id=${organization_id()}`);
  },
  editProfileInfo(payload, id) {
    return Api().put(`/v2/organization/${id}?organization_id=${organization_id()}`, payload);
  },
  updateProfileInfo(payload) {
    return Api().post(`/v2/updateprofileinfo?organization_id=${organization_id()}`, payload);
  },
  getAllSerchResultV2(val, result, filters) {
    var contract_val = filters.hasOwnProperty('archive') ? 4 : 1;
    let url = `/v2/${result}`

    url += `?search=${val}&organization_id=${organization_id()}&contract_active=${contract_val}`
    return Api().get(url);

  },
  deleteUser(payload, id) {

    return Api().delete(`/v2/users/${id}?organization_id=${organization_id()}`);
  },
  getApproverList(payload){
    ///v2/purchase/approver/1?organization_id=29-GET
    return Api().get(`/v2/purchase/approver/${payload.id}?organization_id=${organization_id()}&userId=${payload.user_id}`);


  },
  getMapList(payload) {
    ///v2/purchase/approver/1?organization_id=29-GET
    return Api().get(`/v2/purchase/getMapList/${payload.project_id}?organization_id=${organization_id()}&isDashboard=1`);


  }
};
