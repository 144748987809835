/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import auth from "@/auth/authService";
import UserService from "@/services/UserService.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
import { TokenService } from "@/services/storage.service.js";

import firebase from "firebase/app";
import "firebase/auth";

Vue.use(Router);

const ROLE = {
  SuperAdmin: "Super Admin",
  Admin: "Admin",
  AdminManager: "Admin Manager",
  Moderator: "Moderator",
  User: "User",
  DashboardUser: "Dashboard User"
};

const getPermissions = function(permissions, access, redirect, moduleId) {
  let roleArray = [];
  let promise = permissions.map(x => {
    if (ROLE.hasOwnProperty(x)) {
      roleArray.push({
        role: ROLE[x],
        access: access,
        redirect: redirect
      });
    }
    return x;
  });
  Promise.all(promise);
  return roleArray;
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          redirect: "/dashboard/analytics"
        },
        {
          path: "/dashboard/analytics",
          name: "dashboardAnalytics",
          component: () => import("./views/DashboardAnalytics.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,

              ""
            )
          }
        },
        {
          path: "/dashboard/test",
          name: "dashboardTest",
          component: () => import("./views/Test.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(["SuperAdmin", "Admin"], false, "")
          }
        },
        {
          path: "/dashboard/ecommerce",
          name: "dashboardECommerce",
          component: () => import("./views/DashboardECommerce.vue"),
          meta: {
            rule: "admin",
            permissions: getPermissions(["SuperAdmin", "Admin"], false, "")
          }
        },

        // =============================================================================
        // Application Routes
        // =============================================================================

        {
          path: "/apps/todo",
          name: "todo",
          component: () => import("./views/apps/todo/Todo.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(["SuperAdmin", "Admin"], false, "")
          }
        },
        {
          path: "/apps/projects",
          name: "projects",
          component: () => import("./views/apps/projects/projects.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "Moderator", "User"],
              false,
              PermissionVariables.Project
            )
          }
        },
        // {
        //   path: '/apps/expenses',
        //   name: 'expenses',
        //   component: () =>
        //     import('./views/apps/expenses/expenses.vue'),
        //   meta: {
        //     rule: 'editor',
        //     permissions: getPermissions(['SuperAdmin', 'Admin', 'AdminManager'], false, '')
        //   }
        // },

        {
          path: "/apps/expenseMasterView",
          name: "expenses",
          component: () =>
            import("./views/apps/expenses/expenseMasterView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.Expenses
            )
          }
        },
        {
          path: "/vendorView",
          name: "vendorView",
          component: () => import("@/views/apps/contractvendor/vendorView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.Vendors
            )
          }
        },
        {
          path: "/apps/team",
          name: "team",
          component: () => import("./views/apps/team/team.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },
        {
          path: "/apps/chat",
          name: "chat",
          component: () => import("./views/apps/chat/Chat.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "Moderator", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/apps/email",
          name: "email",
          component: () => import("./views/apps/email/Email.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/apps/calendar/vue-fullcalendar",
          name: "calendarFullCalendar",
          component: () => import("./views/apps/calendar/FullCalendar.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },
        {
          path: "/apps/calendar/vue-fullcalendar/dailyBudget",
          name: "dailyBudget",
          component: () => import("./views/apps/calendar/dailyBudget.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },
        {
          path: "/apps/calendar/vue-simple-calendar",
          name: "calendarSimpleCalendar",
          component: () => import("./views/apps/calendar/SimpleCalendar.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },
        {
          path: "/apps/eCommerce/shop",
          name: "eCommerceShop",
          component: () => import("./views/apps/eCommerce/ECommerceShop.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "eCommerce"
              },
              {
                title: "Shop",
                active: true
              }
            ],
            pageTitle: "Shop",
            rule: "editor"
          }
        },
        {
          path: "/apps/eCommerce/wish-list",
          name: "eCommerceWishList",
          component: () =>
            import("./views/apps/eCommerce/ECommerceWishList.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "eCommerce",
                url: "/apps/eCommerce/shop"
              },
              {
                title: "Wish List",
                active: true
              }
            ],
            pageTitle: "Wish List",
            rule: "editor"
          }
        },
        {
          path: "/apps/eCommerce/checkout",
          name: "eCommerceCheckout",
          component: () =>
            import("./views/apps/eCommerce/ECommerceCheckout.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "eCommerce",
                url: "/apps/eCommerce/shop"
              },
              {
                title: "Checkout",
                active: true
              }
            ],
            pageTitle: "Checkout",
            rule: "editor"
          }
        },

        // COMPANY
        {
          path: "/companyView",
          name: "companyView",
          component: () => import("./views/apps/company/companyView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.MastersPages
            )
          }
        },

        // PROJECTS

        {
          path: "/projects",
          name: "projects",
          component: () => import("./views/apps/projects/projectView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(["SuperAdmin", "Admin"], false, "")
          }
        },
        {
          path: "/editProjectView/:projectID",
          name: "editProjectView",
          component: () => import("./views/apps/projects/editProjectView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "Moderator", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/editProject",
          name: "editProject",
          component: () => import("./views/apps/projects/editProject.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },
        {
          path: "/addLevel",
          name: "addLevel",
          component: () => import("./views/apps/projects/addLevel.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },
        {
          path: "/removeLevel",
          name: "removeLevel",
          component: () => import("./views/apps/projects/removeLevel.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },
        {
          path: "/projectPosList/:projectID",
          name: "projectPosList",
          component: () => import("./views/apps/projects/projectPosList.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "Moderator", "User"],
              false,
              ""
            )
          }
        },

        // DEPARTMENT
        {
          path: "/departmentView",
          name: "departmentView",
          component: () => import("./views/apps/department/departmentView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.MastersPages
            )
          }
        },

        // CATEGORY
        {
          path: "/categoryView",
          name: "categoryView",
          component: () => import("./views/apps/category/categoryView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.MastersPages
            )
          }
        },

        //SUB-CATEGORY
        {
          path: "/subCategoryView/:categoryID/:projectID",
          name: "subCategoryView",
          component: () =>
            import("./views/apps/category/subcategory/subCategoryView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.MastersPages
            )
          }
        },

        // DESIGNATION
        {
          path: "/designationView",
          name: "designationView",
          component: () =>
            import("./views/apps/designation/designationView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.MastersPages
            )
          }
        },
        //SET Name

        {
          path: "/shootLocationView",
          name: "shootLocationView",
          component: () =>
            import("./views/apps/shootLocation/shootLocationView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.MastersPages
            )
          }
        },

        // USERS
        {
          path: "/usersView",
          name: "usersView",
          component: () => import("./views/apps/users/usersView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.Users
            )
          }
        },

        // EMPLOYMENT
        {
          path: "/employmentView",
          name: "employmentView",
          component: () => import("./views/apps/employment/employmentView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.MastersPages
            )
          }
        },

        // Vendor
        {
          path: "/vendorView",
          name: "vendorView",
          component: () => import("./views/apps/vendor/vendorForm.vue"),
          meta: {
            rule: "editor",
            // permissions: getPermissions(['SuperAdmin', 'Admin', 'AdminManager'], false, '')
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "Moderator", "User"],
              false,
              PermissionVariables.Vendors
            )
          }
        },

        // USER ROLES
        {
          path: "/userRoleView",
          name: "userRoleView",
          component: () => import("./views/apps/userRole/userRoleView.vue"),
          meta: {
            rule: "editor"
          }
        },

        //Shoot Location

        {
          path: "/shootLocationView",
          name: "shootLocationView",
          component: () =>
            import("./views/apps/shootLocation/shootLocationView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },

        // COUNTRY
        {
          path: "/countryView",
          name: "countryView",
          component: () => import("./views/apps/country/countryView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.MastersPages
            )
          }
        },
        // DESIGNATION
        {
          path: "/workTypeView",
          name: "workTypeView",
          component: () => import("./views/apps/workType/workTypeView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },

        //  // ORGANIZATION BASED COUNTRY
        //  {
        //   path: '/organizationCountryView',
        //   name: 'countryView',
        //   component: () => import('./views/apps/country/countryView.vue'),
        //   meta: {
        //     rule: 'editor'
        //   }
        // },

        // MODULES
        {
          path: "/modules",
          name: "modules",
          component: () => import("./views/apps/modules/modules.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/addModules",
          name: "addModules",
          component: () => import("./views/apps/modules/addModules.vue"),
          meta: {
            rule: "editor"
          }
        },

        // CUSTOMIZE FIELDS
        {
          path: "/customizeFieldView",
          name: "customizeFieldView",
          component: () =>
            import("./views/apps/customizeField/customizeFieldView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },

        // USER VERIFICATION PAGE
        {
          path: "/VerificationCards",
          name: "VerificationCards",
          component: () =>
            import("./views/apps/UserVerificationPage/verificationCards.vue"),
          meta: {
            rule: "editor"
          }
        },
        // {
        //     path: '/verificationList',
        //     name: 'verificationList',
        //     component: () => import('./views/apps/UserVerificationPage/verificationList.vue'),
        //     meta: {
        //         rule: 'editor'
        //     }
        // },
        {
          path: "/verificationPage",
          name: "verificationPage",
          component: () =>
            import("./views/apps/UserVerificationPage/verificationPage.vue"),
          meta: {
            rule: "editor"
          }
        },

        // add polist
        {
          path: "/verificationPoList",
          name: "verificationPoList",
          component: () =>
            import("./views/apps/UserVerificationPage/verificationPoList.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },

        // add challanLIst
        {
          path: "/verificationChallanList",
          name: "verificationChallanList",
          component: () =>
            import("./views/apps/UserVerificationPage/verificationChallanList.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },

        // USER VERIFICATION PAGE
        {
          path: "/acl",
          name: "User Permission",
          component: () => import("./views/apps/ACL/acl.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(["SuperAdmin"], false, "")
          }
        },

        // CASH MANAGEMENT
        {
          path: "/cashManagementView",
          name: "Cash Management",
          component: () =>
            import("./views/apps/cashManagement/cashManagementView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.AdvanceRequest
            )
          }
        },

        // CURRENCY

        {
          path: "/currency",
          name: "currency",
          component: () => import("./views/apps/currency/currency.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.MastersPages
            )
          }
        },

        {
          path: "/currencyForm",
          name: "currency Form",
          component: () => import("./views/apps/currency/currencyForm.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },

        {
          path: "/currencyList",
          name: "currency List",
          component: () => import("./views/apps/currency/currencyList.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },

        {
          path: "/EditCurrency",
          name: "Edit Currency",
          component: () => import("./views/apps/currency/EditCurrency.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },

        {
          path: "/inventoryView",
          name: "Inventory View",
          component: () => import("./views/apps/inventory/inventoryView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.Inventory
            )
          }
        },
        {
          path: "/budgetView",
          name: "Budget View",
          component: () => import("./views/apps/budget/budgetView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.Budget
            )
          }
        },
        {
          path: "/Organization",
          name: "Organization",
          component: () =>
            import("./views/apps/OrganizationForm/organizationView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },

        {
          path: "/dprAndWorkTypeView",
          name: "dprAndWorkTypeView",
          component: () => import("./views/apps/DPR/dprAndWorkTypeView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "Moderator"],
              false,
              PermissionVariables.DCR
            )
          }
        },

        {
          path: "/dprView",
          name: "DPR View",
          component: () => import("./views/apps/DPR/dprView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "Moderator"],
              false,
              ""
            )
          }
        },
        {
          path: "/dprForm",
          name: "DPR Form",
          component: () => import("./views/apps/DPR/dprForm.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "Moderator"],
              false,
              ""
            )
          }
        },

        {
          path: "/dprList",
          name: "DPR List",
          component: () => import("./views/apps/DPR/dprList.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "Moderator"],
              false,
              ""
            )
          }
        },

        {
          path: "/vendorList",
          name: "Vendor List",
          component: () => import("./views/apps/vendor/vendorList.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },

        {
          path: "/contractView",
          name: "contractView",
          component: () => import("./views/apps/contract/contractView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "Moderator", , "User"],
              false,
              PermissionVariables.Contract
            )
          }
        },
        {
          path: "/contractAllFormView",
          name: "contractAllFormView",
          component: () =>
            import("./views/apps/contract/contractAllFormView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "Moderator", , "User"],
              false,
              ""
            )
          }
        },

        {
          path: "/contract/vendorView",
          name: "contractvendorView",
          component: () => import("./views/apps/contractvendor/vendorView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },

        {
          path: "/contract/userAndLevelView",
          name: "userAndLevelView",
          component: () =>
            import("./views/apps/contractusers/userAndLevelView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },

        {
          path: "/contract/usersView",
          name: "usersView",
          component: () => import("./views/apps/contractusers/usersView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/contract/companyView",
          name: "contractcompanyView",
          component: () =>
            import("./views/apps/contractcompany/companyView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/contract/statusView",
          name: "contractstatusView",
          component: () => import("./views/apps/contractStatus/statusView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/contract/projectType",
          name: "contractprojectType",
          component: () =>
            import("./views/apps/contractprojectType/projectTypeView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/contract/agreement",
          name: "contractagreement",
          component: () =>
            import("./views/apps/contractAgreement/agreementView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/contract/platform",
          name: "contractplatform",
          component: () =>
            import("./views/apps/contractPlatform/platformView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/contract/project",
          name: "contractProjects",
          component: () =>
            import("./views/apps/contractProjects/projectView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/contract/language",
          name: "contractlanguage",
          component: () =>
            import("./views/apps/contractlanguage/languageView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/contract/showType",
          name: "contractshowType",
          component: () =>
            import("./views/apps/contractshowType/showTypeView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/contract/category",
          name: "contractcategory",
          component: () =>
            import("./views/apps/contractcategory/categoryView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/contract/subCategoryView/:categoryID",
          name: "contractsubcategory",
          component: () =>
            import("./views/apps/contractsubcategory/subcategoryView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },
        {
          path: "/contract/reminderType",
          name: "contractreminder",
          component: () =>
            import("./views/apps/contractreminder/reminderView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/contractChartView",
          name: "contractChartView",
          component: () =>
            import("./views/apps/contract/contractChartView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/contractFilter",
          name: "contractFilter",
          component: () => import("./views/apps/contract/contractFilter.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/master/priority",
          name: "priority",
          component: () => import("./views/apps/priorityForm/priorityView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager", "User"],
              false,
              ""
            )
          }
        },
        {
          path: "/master/paymentMode",
          name: "Payment Mode",
          component: () =>
            import("./views/apps/paymentMode/paymentModeView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },
        {
          path: "/master/gst",
          name: "GST",
          component: () => import("./views/apps/gst/gstView.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              ""
            )
          }
        },
        {
          path: "/master",
          name: "Settings",
          component: () => import("./views/apps/projects/settingMaster.vue"),
          meta: {
            rule: "editor",
            permissions: getPermissions(
              ["SuperAdmin", "Admin", "AdminManager"],
              false,
              PermissionVariables.Settings
            )
          }
        },

        {
          path: "/employmentList",
          name: "employmentList",
          component: () => import("./views/apps/employment/employmentList.vue"),
          meta: {
            rule: "editor"
          }
        },

        // =============================================================================
        // UI ELEMENTS
        // =============================================================================
        {
          path: "/ui-elements/data-list/list-view",
          name: "dataListListView",
          component: () =>
            import("@/views/ui-elements/data-list/list-view/DataListListView.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Data List"
              },
              {
                title: "List View",
                active: true
              }
            ],
            pageTitle: "List View",
            rule: "editor"
          }
        },
        {
          path: "/ui-elements/data-list/thumb-view",
          name: "dataListThumbView",
          component: () =>
            import("@/views/ui-elements/data-list/thumb-view/DataListThumbView.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Data List"
              },
              {
                title: "Thumb View",
                active: true
              }
            ],
            pageTitle: "Thumb View",
            rule: "editor"
          }
        },
        {
          path: "/ui-elements/grid/vuesax",
          name: "gridVuesax",
          component: () =>
            import("@/views/ui-elements/grid/vuesax/GridVuesax.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Grid"
              },
              {
                title: "Vuesax",
                active: true
              }
            ],
            pageTitle: "Grid",
            rule: "editor"
          }
        },
        {
          path: "/ui-elements/grid/tailwind",
          name: "gridTailwind",
          component: () =>
            import("@/views/ui-elements/grid/tailwind/GridTailwind.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Grid"
              },
              {
                title: "Tailwind",
                active: true
              }
            ],
            pageTitle: "Tailwind Grid",
            rule: "editor"
          }
        },
        {
          path: "/ui-elements/colors",
          name: "colors",
          component: () => import("./views/ui-elements/colors/Colors.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Colors",
                active: true
              }
            ],
            pageTitle: "Colors",
            rule: "editor"
          }
        },
        {
          path: "/ui-elements/card/basic",
          name: "basicCards",
          component: () => import("./views/ui-elements/card/CardBasic.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Card"
              },
              {
                title: "Basic Cards",
                active: true
              }
            ],
            pageTitle: "Basic Cards",
            rule: "editor"
          }
        },
        {
          path: "/ui-elements/card/statistics",
          name: "statisticsCards",
          component: () =>
            import("./views/ui-elements/card/CardStatistics.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Card"
              },
              {
                title: "Statistics Cards",
                active: true
              }
            ],
            pageTitle: "Statistics Card",
            rule: "editor"
          }
        },
        {
          path: "/ui-elements/card/analytics",
          name: "analyticsCards",
          component: () => import("./views/ui-elements/card/CardAnalytics.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Card"
              },
              {
                title: "Analytics Card",
                active: true
              }
            ],
            pageTitle: "Analytics Card",
            rule: "editor"
          }
        },
        {
          path: "/ui-elements/card/card-actions",
          name: "cardActions",
          component: () => import("./views/ui-elements/card/CardActions.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Card"
              },
              {
                title: "Card Actions",
                active: true
              }
            ],
            pageTitle: "Card Actions",
            rule: "editor"
          }
        },
        {
          path: "/ui-elements/card/card-colors",
          name: "cardColors",
          component: () => import("./views/ui-elements/card/CardColors.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Card"
              },
              {
                title: "Card Colors",
                active: true
              }
            ],
            pageTitle: "Card Colors",
            rule: "editor"
          }
        },
        {
          path: "/ui-elements/table",
          name: "table",
          component: () => import("./views/ui-elements/table/Table.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Table",
                active: true
              }
            ],
            pageTitle: "Table",
            rule: "editor"
          }
        },
        {
          path: "/ui-elements/ag-grid-table",
          name: "agGridTable",
          component: () =>
            import("./views/ui-elements/ag-grid-table/AgGridTable.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "agGrid Table",
                active: true
              }
            ],
            pageTitle: "agGrid Table",
            rule: "editor"
          }
        },

        // =============================================================================
        // COMPONENT ROUTES
        // =============================================================================
        {
          path: "/components/alert",
          name: "componentAlert",
          component: () => import("@/views/components/vuesax/alert/Alert.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Alert",
                active: true
              }
            ],
            pageTitle: "Alert",
            rule: "editor"
          }
        },
        {
          path: "/components/avatar",
          name: "componentAvatar",
          component: () =>
            import("@/views/components/vuesax/avatar/Avatar.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Avatar",
                active: true
              }
            ],
            pageTitle: "Avatar",
            rule: "editor"
          }
        },
        {
          path: "/components/breadcrumb",
          name: "componentBreadcrumb",
          component: () =>
            import("@/views/components/vuesax/breadcrumb/Breadcrumb.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Breadcrumb",
                active: true
              }
            ],
            pageTitle: "Breadcrumb",
            rule: "editor"
          }
        },
        {
          path: "/components/button",
          name: "componentButton",
          component: () =>
            import("@/views/components/vuesax/button/Button.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Button",
                active: true
              }
            ],
            pageTitle: "Button",
            rule: "editor"
          }
        },
        {
          path: "/components/button-group",
          name: "componentButtonGroup",
          component: () =>
            import("@/views/components/vuesax/button-group/ButtonGroup.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Button Group",
                active: true
              }
            ],
            pageTitle: "Button Group",
            rule: "editor"
          }
        },
        {
          path: "/components/chip",
          name: "componentChip",
          component: () => import("@/views/components/vuesax/chip/Chip.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Chip",
                active: true
              }
            ],
            pageTitle: "Chip",
            rule: "editor"
          }
        },
        {
          path: "/components/collapse",
          name: "componentCollapse",
          component: () =>
            import("@/views/components/vuesax/collapse/Collapse.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Collapse",
                active: true
              }
            ],
            pageTitle: "Collapse",
            rule: "editor"
          }
        },
        {
          path: "/components/dialogs",
          name: "componentDialog",
          component: () =>
            import("@/views/components/vuesax/dialogs/Dialogs.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Dialogs",
                active: true
              }
            ],
            pageTitle: "Dialogs",
            rule: "editor"
          }
        },
        {
          path: "/components/divider",
          name: "componentDivider",
          component: () =>
            import("@/views/components/vuesax/divider/Divider.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Divider",
                active: true
              }
            ],
            pageTitle: "Divider",
            rule: "editor"
          }
        },
        {
          path: "/components/dropdown",
          name: "componentDropDown",
          component: () =>
            import("@/views/components/vuesax/dropdown/Dropdown.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Dropdown",
                active: true
              }
            ],
            pageTitle: "Dropdown",
            rule: "editor"
          }
        },
        {
          path: "/components/list",
          name: "componentList",
          component: () => import("@/views/components/vuesax/list/List.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "List",
                active: true
              }
            ],
            pageTitle: "List",
            rule: "editor"
          }
        },
        {
          path: "/components/loading",
          name: "componentLoading",
          component: () =>
            import("@/views/components/vuesax/loading/Loading.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Loading",
                active: true
              }
            ],
            pageTitle: "Loading",
            rule: "editor"
          }
        },
        {
          path: "/components/navbar",
          name: "componentNavbar",
          component: () =>
            import("@/views/components/vuesax/navbar/Navbar.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Navbar",
                active: true
              }
            ],
            pageTitle: "Navbar",
            rule: "editor"
          }
        },
        {
          path: "/components/notifications",
          name: "componentNotifications",
          component: () =>
            import("@/views/components/vuesax/notifications/Notifications.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Notifications",
                active: true
              }
            ],
            pageTitle: "Notifications",
            rule: "editor"
          }
        },
        {
          path: "/components/pagination",
          name: "componentPagination",
          component: () =>
            import("@/views/components/vuesax/pagination/Pagination.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Pagination",
                active: true
              }
            ],
            pageTitle: "Pagination",
            rule: "editor"
          }
        },
        {
          path: "/components/popup",
          name: "componentPopup",
          component: () => import("@/views/components/vuesax/popup/Popup.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Popup",
                active: true
              }
            ],
            pageTitle: "Popup",
            rule: "editor"
          }
        },
        {
          path: "/components/progress",
          name: "componentProgress",
          component: () =>
            import("@/views/components/vuesax/progress/Progress.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Progress",
                active: true
              }
            ],
            pageTitle: "Progress",
            rule: "editor"
          }
        },
        {
          path: "/components/sidebar",
          name: "componentSidebar",
          component: () =>
            import("@/views/components/vuesax/sidebar/Sidebar.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Sidebar",
                active: true
              }
            ],
            pageTitle: "Sidebar",
            rule: "editor"
          }
        },
        {
          path: "/components/slider",
          name: "componentSlider",
          component: () =>
            import("@/views/components/vuesax/slider/Slider.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Slider",
                active: true
              }
            ],
            pageTitle: "Slider",
            rule: "editor"
          }
        },
        {
          path: "/components/tabs",
          name: "componentTabs",
          component: () => import("@/views/components/vuesax/tabs/Tabs.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Tabs",
                active: true
              }
            ],
            pageTitle: "Tabs",
            rule: "editor"
          }
        },
        {
          path: "/components/tooltip",
          name: "componentTooltip",
          component: () =>
            import("@/views/components/vuesax/tooltip/Tooltip.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Tooltip",
                active: true
              }
            ],
            pageTitle: "Tooltip",
            rule: "editor"
          }
        },
        {
          path: "/components/upload",
          name: "componentUpload",
          component: () =>
            import("@/views/components/vuesax/upload/Upload.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Components"
              },
              {
                title: "Upload",
                active: true
              }
            ],
            pageTitle: "Upload",
            rule: "editor"
          }
        },

        // =============================================================================
        // FORMS
        // =============================================================================
        // =============================================================================
        // FORM ELEMENTS
        // =============================================================================
        {
          path: "/forms/form-elements/select",
          name: "formElementSelect",
          component: () =>
            import("./views/forms/form-elements/select/Select.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Form Elements"
              },
              {
                title: "Select",
                active: true
              }
            ],
            pageTitle: "Select",
            rule: "editor"
          }
        },
        {
          path: "/forms/form-elements/switch",
          name: "formElementSwitch",
          component: () =>
            import("./views/forms/form-elements/switch/Switch.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Form Elements"
              },
              {
                title: "Switch",
                active: true
              }
            ],
            pageTitle: "Switch",
            rule: "editor"
          }
        },
        {
          path: "/forms/form-elements/checkbox",
          name: "formElementCheckbox",
          component: () =>
            import("./views/forms/form-elements/checkbox/Checkbox.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Form Elements"
              },
              {
                title: "Checkbox",
                active: true
              }
            ],
            pageTitle: "Checkbox",
            rule: "editor"
          }
        },
        {
          path: "/forms/form-elements/radio",
          name: "formElementRadio",
          component: () =>
            import("./views/forms/form-elements/radio/Radio.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Form Elements"
              },
              {
                title: "Radio",
                active: true
              }
            ],
            pageTitle: "Radio",
            rule: "editor"
          }
        },
        {
          path: "/forms/form-elements/input",
          name: "formElementInput",
          component: () =>
            import("./views/forms/form-elements/input/Input.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Form Elements"
              },
              {
                title: "Input",
                active: true
              }
            ],
            pageTitle: "Input",
            rule: "editor"
          }
        },
        {
          path: "/forms/form-elements/number-input",
          name: "formElementNumberInput",
          component: () =>
            import("./views/forms/form-elements/number-input/NumberInput.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Form Elements"
              },
              {
                title: "Number Input",
                active: true
              }
            ],
            pageTitle: "Number Input",
            rule: "editor"
          }
        },
        {
          path: "/forms/form-elements/textarea",
          name: "formElementTextarea",
          component: () =>
            import("./views/forms/form-elements/textarea/Textarea.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Form Elements"
              },
              {
                title: "Textarea",
                active: true
              }
            ],
            pageTitle: "Textarea",
            rule: "editor"
          }
        },
        // -------------------------------------------------------------------------------------------------------------------------------------------
        {
          path: "/forms/form-layouts",
          name: "formsFormLayouts",
          component: () => import("@/views/forms/FormLayouts.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Forms"
              },
              {
                title: "Form Layouts",
                active: true
              }
            ],
            pageTitle: "Form Layouts",
            rule: "editor"
          }
        },
        {
          path: "/forms/form-wizard",
          name: "extraComponentFormWizard",
          component: () => import("@/views/forms/form-wizard/FormWizard.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extra Components"
              },
              {
                title: "Form Wizard",
                active: true
              }
            ],
            pageTitle: "Form Wizard",
            rule: "editor"
          }
        },
        {
          path: "/forms/form-validation",
          name: "extraComponentFormValidation",
          component: () =>
            import("@/views/forms/form-validation/FormValidation.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extra Components"
              },
              {
                title: "Form Validation",
                active: true
              }
            ],
            pageTitle: "Form Validation",
            rule: "editor"
          }
        },
        {
          path: "/forms/form-input-group",
          name: "extraComponentFormInputGroup",
          component: () =>
            import("@/views/forms/form-input-group/FormInputGroup.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extra Components"
              },
              {
                title: "Form Input Group",
                active: true
              }
            ],
            pageTitle: "Form Input Group",
            rule: "editor"
          }
        },

        // =============================================================================
        // Pages Routes
        // =============================================================================
        {
          path: "/pages/profile",
          name: "pageProfile",
          component: () => import("@/views/pages/Profile.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/faq",
          name: "pageFAQ",
          component: () => import("@/views/pages/Faq.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Pages"
              },
              {
                title: "FAQ",
                active: true
              }
            ],
            pageTitle: "FAQ",
            rule: "editor"
          }
        },
        {
          path: "/pages/knowledge-base",
          name: "pageKnowledgeBase",
          component: () => import("@/views/pages/KnowledgeBase.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Pages"
              },
              {
                title: "KnowledgeBase",
                active: true
              }
            ],
            pageTitle: "KnowledgeBase",
            rule: "editor"
          }
        },
        {
          path: "/pages/knowledge-base/category",
          name: "pageKnowledgeBaseCategory",
          component: () => import("@/views/pages/KnowledgeBaseCategory.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Pages"
              },
              {
                title: "KnowledgeBase",
                url: "/pages/knowledge-base"
              },
              {
                title: "Category",
                active: true
              }
            ],
            rule: "editor"
          }
        },
        {
          path: "/pages/knowledge-base/category/question",
          name: "pageKnowledgeBaseCategoryQuestion",
          component: () =>
            import("@/views/pages/KnowledgeBaseCategoryQuestion.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Pages"
              },
              {
                title: "KnowledgeBase",
                url: "/pages/knowledge-base"
              },
              {
                title: "Category",
                url: "/pages/knowledge-base/category"
              },
              {
                title: "Question",
                active: true
              }
            ],
            rule: "editor"
          }
        },
        {
          path: "/pages/search",
          name: "pageSearch",
          component: () => import("@/views/pages/Search.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Pages"
              },
              {
                title: "Search",
                active: true
              }
            ],
            pageTitle: "Search",
            rule: "editor"
          }
        },
        {
          path: "/pages/invoice",
          name: "pageInvoice",
          component: () => import("@/views/pages/Invoice.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Pages"
              },
              {
                title: "Invoice",
                active: true
              }
            ],
            pageTitle: "Invoice",
            rule: "editor"
          }
        },

        // =============================================================================
        // CHARTS & MAPS
        // =============================================================================
        {
          path: "/charts-and-maps/charts/apex-charts",
          name: "extraComponentChartsApexCharts",
          component: () =>
            import("@/views/charts-and-maps/charts/apex-charts/ApexCharts.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Charts & Maps"
              },
              {
                title: "Apex Charts",
                active: true
              }
            ],
            pageTitle: "Apex Charts",
            rule: "editor"
          }
        },
        {
          path: "/charts-and-maps/charts/chartjs",
          name: "extraComponentChartsChartjs",
          component: () =>
            import("@/views/charts-and-maps/charts/chartjs/Chartjs.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Charts & Maps"
              },
              {
                title: "chartjs",
                active: true
              }
            ],
            pageTitle: "chartjs",
            rule: "editor"
          }
        },
        {
          path: "/charts-and-maps/charts/echarts",
          name: "extraComponentChartsEcharts",
          component: () =>
            import("@/views/charts-and-maps/charts/echarts/Echarts.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Charts & Maps"
              },
              {
                title: "echarts",
                active: true
              }
            ],
            pageTitle: "echarts",
            rule: "editor"
          }
        },
        {
          path: "/charts-and-maps/maps/google-map",
          name: "extraComponentMapsGoogleMap",
          component: () =>
            import("@/views/charts-and-maps/maps/google-map/GoogleMap.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Charts & Maps"
              },
              {
                title: "Google Map",
                active: true
              }
            ],
            pageTitle: "Google Map",
            rule: "editor"
          }
        },

        // =============================================================================
        // EXTENSIONS
        // =============================================================================
        {
          path: "/extensions/select",
          name: "extraComponentSelect",
          component: () =>
            import("@/views/components/extra-components/select/Select.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extra Components"
              },
              {
                title: "Select",
                active: true
              }
            ],
            pageTitle: "Select",
            rule: "editor"
          }
        },
        {
          path: "/extensions/quill-editor",
          name: "extraComponentQuillEditor",
          component: () =>
            import("@/views/components/extra-components/quill-editor/QuillEditor.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extra Components"
              },
              {
                title: "Quill Editor",
                active: true
              }
            ],
            pageTitle: "Quill Editor",
            rule: "editor"
          }
        },
        {
          path: "/extensions/drag-and-drop",
          name: "extraComponentDragAndDrop",
          component: () =>
            import("@/views/components/extra-components/drag-and-drop/DragAndDrop.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extra Components"
              },
              {
                title: "Drag & Drop",
                active: true
              }
            ],
            pageTitle: "Drag & Drop",
            rule: "editor"
          }
        },
        {
          path: "/extensions/datepicker",
          name: "extraComponentDatepicker",
          component: () =>
            import("@/views/components/extra-components/datepicker/Datepicker.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extra Components"
              },
              {
                title: "Datepicker",
                active: true
              }
            ],
            pageTitle: "Datepicker",
            rule: "editor"
          }
        },
        {
          path: "/extensions/datetime-picker",
          name: "extraComponentDatetimePicker",
          component: () =>
            import("@/views/components/extra-components/datetime-picker/DatetimePicker.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extra Components"
              },
              {
                title: "Datetime Picker",
                active: true
              }
            ],
            pageTitle: "Datetime Picker",
            rule: "editor"
          }
        },
        {
          path: "/extensions/access-control-editor",
          name: "extraComponentAccessControlEditor",
          component: () =>
            import("@/views/components/extra-components/access-control/AccessControlEditor.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extensions"
              },
              {
                title: "Access Control"
              },
              {
                title: "Editor View",
                active: true
              }
            ],
            pageTitle: "Editor View",
            rule: "editor"
          }
        },
        {
          path: "/extensions/access-control-admin",
          name: "extraComponentAccessControlOnlyAdmin",
          component: () =>
            import("@/views/components/extra-components/access-control/AccessControlAdmin.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extensions"
              },
              {
                title: "Access Control"
              },
              {
                title: "Admin View",
                active: true
              }
            ],
            pageTitle: "Admin View",
            rule: "admin"
          }
        },
        {
          path: "/extensions/i18n",
          name: "extraComponentI18n",
          component: () =>
            import("@/views/components/extra-components/I18n.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extensions"
              },
              {
                title: "I18n",
                active: true
              }
            ],
            pageTitle: "I18n",
            rule: "editor"
          }
        },
        {
          path: "/extensions/carousel",
          name: "extraComponentCarousel",
          component: () =>
            import("@/views/components/extra-components/carousel/Carousel.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extensions"
              },
              {
                title: "Carousel",
                active: true
              }
            ],
            pageTitle: "Carousel",
            rule: "editor"
          }
        },
        {
          path: "/extensions/clipboard",
          name: "extraComponentClipboard",
          component: () =>
            import("@/views/components/extra-components/clipboard/Clipboard.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extensions"
              },
              {
                title: "Clipboard",
                active: true
              }
            ],
            pageTitle: "Clipboard",
            rule: "editor"
          }
        },
        {
          path: "/extensions/context-menu",
          name: "extraComponentContextMenu",
          component: () =>
            import("@/views/components/extra-components/context-menu/ContextMenu.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extensions"
              },
              {
                title: "Context Menu",
                active: true
              }
            ],
            pageTitle: "Context Menu",
            rule: "editor"
          }
        },
        {
          path: "/extensions/star-ratings",
          name: "extraComponentStarRatings",
          component: () =>
            import("@/views/components/extra-components/star-ratings/StarRatings.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extensions"
              },
              {
                title: "Star Ratings",
                active: true
              }
            ],
            pageTitle: "Star Ratings",
            rule: "editor"
          }
        },
        {
          path: "/extensions/autocomplete",
          name: "extraComponentAutocomplete",
          component: () =>
            import("@/views/components/extra-components/autocomplete/Autocomplete.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extensions"
              },
              {
                title: "Autocomplete",
                active: true
              }
            ],
            pageTitle: "Autocomplete",
            rule: "editor"
          }
        },
        {
          path: "/extensions/tree",
          name: "extraComponentTree",
          component: () =>
            import("@/views/components/extra-components/tree/Tree.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Extensions"
              },
              {
                title: "Tree",
                active: true
              }
            ],
            pageTitle: "Tree",
            rule: "editor"
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/callback",
          name: "authCallback",
          component: () => import("@/views/Callback.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/login",
          name: "pageLogin",
          component: () => import("@/views/pages/Login.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/expense/:type/:token",
          name: "pageExpense",
          component: () => import("@/views/pages/Expense.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/no-permission",
          name: "NoPermission",
          component: () => import("@/views/pages/NoPermissions.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/register",
          name: "pageRegister",
          component: () => import("@/views/pages/Register.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/forgot-password",
          name: "pageForgotPassword",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/reset-password",
          name: "pageResetPassword",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/landingPage",
          name: "landingPage",
          component: () => import("@/views/pages/landingPage.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/lock-screen",
          name: "pageLockScreen",
          component: () => import("@/views/pages/LockScreen.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/comingsoon",
          name: "pageComingSoon",
          component: () => import("@/views/pages/ComingSoon.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/error-404",
          name: "pageError404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/error-500",
          name: "pageError500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/not-authorized",
          name: "pageNotAuthorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/maintenance",
          name: "pageMaintenance",
          component: () => import("@/views/pages/Maintenance.vue"),
          meta: {
            rule: "editor"
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404"
    }
  ]
});
let that = router;
router.afterEach(to => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  let toRoute = to.path.split("/");
  console.log(toRoute, "toRoute", to);

  if (
    to.path === "/login" ||
    toRoute[1] === "expense" ||
    to.path === "/pages/forgot-password" ||
    to.path === "/pages/error-404" ||
    to.path === "/pages/error-500" ||
    to.path === "/pages/register" ||
    to.path === "/callback" ||
    to.path === "/pages/comingsoon"
  ) {
    let token = TokenService.getToken();
    if (token) {
      //do a api call, if 401, stay here and delete token
      UserService.getProfileInfo()
        .then(response => {
          const { data } = response;
          if (!data.err) {
            clg;
            window.localStorage.setItem("UserType", data.data.checkUserType);

            that.push({
              path: "/dashboard/analytics"
            });
          } else {
            TokenService.removeToken();
            return next();
          }
        })
        .catch(error => {
          TokenService.removeToken();
          return next();
        });
    } else {
      return next();
    }
  } else if (auth.isAuthenticated()) {
    return next();
  } else {
    router.push({
      path: "/login",
      query: {
        to: to.path
      }
    });
  }
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { permissions } = to.meta;

  const currentUserValue = localStorage.getItem("UserRole"); // authenticationService.currentUserValue;
  let getuserRole = localStorage.getItem("UserRole");
  if (getuserRole && getuserRole != "Admin" && getuserRole != "Super Admin") {
    const modulePermissions = localStorage.getItem("moduleAccess")
      ? localStorage.getItem("moduleAccess").split(",")
      : "";
    const pagePermission = localStorage.getItem("permissionPageAccess")
      ? localStorage.getItem("permissionPageAccess").split(",")
      : ""; // authenticationService.currentUserValue;
    // return next({
    //   path: "/dashboard/analytics"
    // });

    if (modulePermissions && modulePermissions.length > 0) {
      if (
        permissions &&
        permissions[0].redirect &&
        !modulePermissions.includes(permissions[0].redirect) &&
        !pagePermission.includes(permissions[0].redirect)
      ) {
        // alert();
        return next({
          path: "/pages/not-authorized"
        });
      }
    }
  }
  next();
});

export default router;
