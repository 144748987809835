export default {
  Dashboard: "1000",
  // pagePermissionIds
  View_Dashboard_module_with_reports: "1",
  Welcome_Screen: "26",

  // moduleIds
  Project: "1001",
  // pagePermissionIds
  MastersPages: "32",
  AddProject: "33",
  ViewOrganizationProjects: "31",
  projectEdit: "36",
  projectArchive: "37",
  projectDelete: "38",
  viewEditSection: "39",
  projectCreate: "40",

  Users: "1002",
  // pagePermissionIds
  UserAdd: "6",
  UserEdit: "7",
  UserDelete: "8",
  UserView: "9",
  UserExport: "10",

  Vendors: "1003",
  // pagePermissionIds
  VendorAdd: "11",
  VendorEdit: "12",
  VendorArchive: "13",
  VendorExport: "14",

  Expenses: "1004",
  // pagePermissionIds
  CreateExpenses: "2",
  ViewProjectExpense: "3",
  ViewOrgExpense: "4",
  ExpenseStatue: "5",

  Budget: "1005",
  // pagePermissionIds
  BudgetAdd: "15",
  BudgetEdit: "16",
  BudgetDelete: "17",
  BudgetView: "18",
  BudgetExport: "19",

  Inventory: "1006",
  // pagePermissionIds
  CreateInventory: "20",
  InventoryList: "21",

  AdvanceRequest: "1007",
  // pagePermissionIds
  ViewAllocateRequestList: "22",
  CashAllocatedUserList: "23",

  Settings: "1008",
  // pagePermissionIds
  SettingsAdd: "24",
  SettingsView: "25",
  PaymentAdd: "27",
  PaymentView: "28",
  PriorityAdd: "29",
  PriorityView: "30",

  DCR: "1009",
  Contract: "1010"
};
