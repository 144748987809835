// en, de, fr, pt
export default {
	en: {
		Dashboard: 'Dashboard',
		eCommerce: 'eCommerce',
		Apps: 'Apps',
		Todo: 'Todo',
		Chat: 'Chat',
		Email: 'Email',
		Calendar: 'Calendar',
    FullCalendar: 'Full Calendar',
    SimpleCalendar: 'Simple Calendar',
		Shop: 'Shop',
		Cart: 'Cart',
    WishList: 'Wish List',
		Checkout: 'Checkout',
		UIElements: 'UI Elements',
		DataList: 'Data List',
		ListView: 'List View',
		ThumbView: 'Thumb View',
		Grid: 'Grid',
		Vuesax: 'Vuesax',
		Tailwind: 'Tailwind',
		Colors: 'Colors',
		Card: 'Card',
		Basic: 'Basic',
		Statistics: 'Statistics',
		Analytics: 'Analytics',
		CardActions: 'Card Actions',
    CardColors: 'Card Colors',
		Table: 'Table',
    agGridTable: 'agGrid Table',
		Components: 'Components',
		Alert: 'Alert',
		Avatar: 'Avatar',
		Badge: 'Badge',
		Breadcrumb: 'Breadcrumb',
		Button: 'Button',
    ButtonGroup: 'Button Group',
		Chip: 'Chip',
		Collapse: 'Collapse',
		Dialogs: 'Dialogs',
		Divider: 'Divider',
		DropDown: 'DropDown',
		List: 'List',
		Loading: 'Loading',
		Navbar: 'Navbar',
		Notifications: 'Notifications',
		Pagination: 'Pagination',
		Popup: 'Popup',
		Progress: 'Progress',
		Sidebar: 'Sidebar',
		Slider: 'Slider',
		Tabs: 'Tabs',
		Tooltip: 'Tooltip',
		Upload: 'Upload',
		FormsAndTable: 'Forms & Table',
		FormElements: 'Form Elements',
		Select: 'Select',
		Switch: 'Switch',
		Checkbox: 'Checkbox',
		Radio: 'Radio',
		Input: 'Input',
		NumberInput: 'Number Input',
		Textarea: 'Textarea',
		FormLayouts: 'Form Layouts',
		FormWizard: 'Form Wizard',
		FormValidation: 'Form Validation',
    FormInputGroup: 'Form Input Group',
		Pages: 'Pages',
		Authentication: 'Authentication',
		Login: 'Login',
		Register: 'Register',
		ForgotPassword: 'Forgot Password',
		ResetPassword: 'Reset Password',
		LockScreen: 'Lock Screen',
    Miscellaneous: 'Miscellaneous',
		ComingSoon: 'Coming Soon',
		Error: 'Error',
		404: '404',
		500: '500',
		NotAuthorized: 'Not Authorized',
		Maintenance: 'Maintenance',
		Profile: 'Profile',
		Invoice: 'Invoice',
		FAQ: 'FAQ',
		Search: 'Search',
		KnowledgeBase: 'Knowledge Base',
		ChartsAndMaps: 'Charts & Maps',
		Charts: 'Charts',
		ApexCharts: 'Apex Charts',
		chartjs: 'chartjs',
		echarts: 'echarts',
		chartist: 'chartist',
		GoogleMap: 'Google Map',
		Extensions: 'Extensions',
		QuillEditor: 'Quill Editor',
		DragAndDrop: 'Drag & Drop',
		Datepicker: 'Datepicker',
    DatetimePicker: 'Datetime Picker',
		AccessControl: 'Access Control',
		EditorView: 'Editor View',
		AdminView: 'Admin View',
		I18n: 'I18n',
		Carousel: 'Carousel',
		Clipboard: 'Clipboard',
		ContextMenu: 'Context Menu',
    StarRatings: 'Star Ratings',
    Autocomplete: 'Autocomplete',
    Tree: 'Tree',
		Others: 'Others',
		MenuLevels: 'Menu Levels',
		MenuLevel2p1: 'Menu Level 2.1',
		MenuLevel2p2: 'Menu Level 2.2',
		MenuLevel3p1: 'Menu Level 3.1',
		MenuLevel3p2: 'Menu Level 3.2',
		DisabledMenu: 'Disabled Menu',
		Support: 'Support',
		Documentation: 'Documentation',
		RaiseSupport: 'Raise Support',
		demoTitle: 'Card Title',
		demoText: 'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.'
	},
	de: {
		Dashboard: 'Instrumententafel',
		eCommerce: 'eCommerce',
		Apps: 'Apps',
		Todo: 'Machen',
		Chat: 'Plaudern',
		Email: 'Email',
		Calendar: 'Kalender',
    FullCalendar: 'Calendrier completVollständiger Kalender',
    SimpleCalendar: 'Einfacher Kalender',
		Shop: 'Geschäft',
		Cart: 'Wagen',
    WishList: 'Wunschzettel',
		Checkout: 'Auschecken',
		UIElements: 'Elemente der Benutzeroberfläche',
		DataList: 'Datenliste',
		ListView: 'Listenansicht',
		ThumbView: 'Daumenansicht',
		Grid: 'Gitter',
		Vuesax: 'Vuesax',
		Tailwind: 'Tailwind',
		Colors: 'Farben',
		Card: 'Karte',
		Basic: 'Basic',
		Statistics: 'Statistiken',
		Analytics: 'Analytics',
		CardActions: 'Kartenaktionen',
    CardColors: 'Kartenfarben',
		Table: 'Tabelle',
    agGridTable: 'agGrid-Tabelle',
		Components: 'Komponenten',
		Alert: 'Warnen',
		Avatar: 'Benutzerbild',
		Badge: 'Abzeichen',
		Breadcrumb: 'Breadcrumb',
		Button: 'Taste',
    ButtonGroup: 'Schaltflächengruppe',
		Chip: 'Chip',
		Collapse: 'Zusammenbruch',
		Dialogs: 'Dialoge',
		Divider: 'Teiler',
		DropDown: 'Dropdown-Liste',
		List: 'Liste',
		Loading: 'Wird geladen',
		Navbar: 'Navbar',
		Notifications: 'Benachrichtigungen',
		Pagination: 'Seitennummerierung',
		Popup: 'Pop-up',
		Progress: 'Fortschritt',
		Sidebar: 'Seitenleiste',
		Slider: 'Schieberegler',
		Tabs: 'Tabs',
		Tooltip: 'QuickInfo',
		Upload: 'Hochladen',
		FormsAndTable: 'Formulare und Tabelle',
		FormElements: 'Formularelemente',
		Select: 'Wählen',
		Switch: 'Schalter',
		Checkbox: 'Ankreuzfeld',
		Radio: 'Radio',
		Input: 'Eingang',
		NumberInput: 'Nummer eingeben',
		Textarea: 'Textbereich',
		FormLayouts: 'Formularlayouts',
		FormWizard: 'Formzauberer',
		FormValidation: 'Formularvalidierung',
    FormInputGroup: 'Formulareingabegruppe',
		Pages: 'Seiten',
		Authentication: 'Authentifizierung',
		Login: 'Anmeldung',
		Register: 'Registrieren',
		ForgotPassword: 'Passwort vergessen',
		ResetPassword: 'Passwort zurücksetzen',
		LockScreen: 'Bildschirm sperren',
    Miscellaneous: 'Verschiedenes',
		ComingSoon: 'Demnächst',
		Error: 'Error',
		404: '404',
		500: '500',
		NotAuthorized: 'Nicht berechtigt',
		Maintenance: 'Instandhaltung',
		Profile: 'Profil',
		Invoice: 'Rechnung',
		FAQ: 'FAQ',
		Search: 'Suche',
		KnowledgeBase: 'Wissensbasis',
		ChartsAndMaps: 'Diagramme und Karten',
		Charts: 'Diagramme',
		ApexCharts: 'Apex Charts',
		chartjs: 'chartjs',
		echarts: 'echarts',
		chartist: 'chartist',
		GoogleMap: 'Google Karte',
		Extensions: 'Erweiterungen',
		QuillEditor: 'Quill-Editor',
		DragAndDrop: 'Ziehen und loslassen',
		Datepicker: 'Datumsauswahl',
    DatetimePicker: 'Datum / Uhrzeit-Auswahl',
		AccessControl: 'Zugangskontrolle',
		EditorView: 'Editoransicht',
		AdminView: 'Admin View',
		I18n: 'I18n',
		Carousel: 'Karussell',
		Clipboard: 'Zwischenablage',
		ContextMenu: 'Kontextmenü',
    StarRatings: 'Sterne Bewertungen',
    Autocomplete: 'Autovervollständigung',
    Tree: 'Baum',
		Others: 'Andere',
		MenuLevels: 'Menüebenen',
		MenuLevel2p1: 'Menüebene 2.1',
		MenuLevel2p2: 'Menüebene 2.2',
		MenuLevel3p1: 'Menüebene 3.1',
		MenuLevel3p2: 'Menüebene 3.2',
		DisabledMenu: 'Deaktiviertes Menü',
		Support: 'Unterstützung',
		Documentation: 'Dokumentation',
		RaiseSupport: 'Unterstützung erheben',
		demoTitle: 'Kartentitel',
		demoText: 'Kuchen Sesam Schnaps Cupcake Lebkuchen Dänisch Ich liebe Lebkuchen. Apfelkuchen-Torte-Jujuben chupa chups muffin halvah Lutscher. Schokoladenkuchenhaferkuchen Tiramisu-Marzipanzuckerpflaume. Donut süße Kuchen Hafer Kuchen Dragée Obstkuchen Zuckerwatte Zitrone Tropfen.',
	},
	fr: {
		Dashboard: 'Tableau de bord',
		eCommerce: 'commerce électronique',
		Apps: 'APPLICATIONS',
		Todo: 'Faire',
		Chat: 'Bavarder',
		Email: 'Email',
		Calendar: 'Calendrier',
    FullCalendar: 'Calendrier complet',
    SimpleCalendar: 'Calendrier simple',
		Shop: 'Boutique',
		Cart: 'Chariot',
    WishList: 'Liste de souhaits',
		Checkout: 'Check-out',
		UIElements: 'ELEMENTS D\'UI',
		DataList: 'Liste de données',
		ListView: 'Voir la liste',
		ThumbView: 'Thumb View',
		Grid: 'la grille',
		Vuesax: 'Vuesax',
		Tailwind: 'Tailwind',
		Colors: 'Couleurs',
		Card: 'Carte',
		Basic: 'De base',
		Statistics: 'Statistiques',
		Analytics: 'Analytique',
		CardActions: 'Actions de carte',
    CardColors: 'Couleurs de la carte',
		Table: 'Table',
    agGridTable: 'tableau agGrid',
		Components: 'Composants',
		Alert: 'Alerte',
		Avatar: 'Avatar',
		Badge: 'Badge',
		Breadcrumb: 'Breadcrumb',
		Button: 'Bouton',
    ButtonGroup: 'Groupe de boutons',
		Chip: 'Puce',
		Collapse: 'Effondrer',
		Dialogs: 'Dialogues',
		Divider: 'Diviseur',
		DropDown: 'Menu déroulant',
		List: 'liste',
		Loading: 'Chargement',
		Navbar: 'Barre de navigation',
		Notifications: 'Les notifications',
		Pagination: 'Pagination',
		Popup: 'Apparaitre',
		Progress: 'Le progrès',
		Sidebar: 'Barre latérale',
		Slider: 'Curseur',
		Tabs: 'Onglets',
		Tooltip: 'Info-bulle',
		Upload: 'Télécharger',
		FormsAndTable: 'Formulaires et tableau',
		FormElements: 'Éléments de formulaire',
		Select: 'Sélectionner',
		Switch: 'Commutateur',
		Checkbox: 'Case à cocher',
		Radio: 'Radio',
		Input: 'Contribution',
		NumberInput: 'Nombre d\'entrée',
		Textarea: 'Textarea',
		FormLayouts: 'Dispositions de formulaire',
		FormWizard: 'Assistant de formulaire',
		FormValidation: 'Validation du formulaire',
    FormInputGroup: 'Groupe de saisie de formulaire',
		Pages: 'Pages',
		Authentication: 'Authentification',
		Login: 'S\'identifier',
		Register: 'registre',
		ForgotPassword: 'Mot de passe oublié',
		ResetPassword: 'réinitialiser le mot de passe',
		LockScreen: 'Écran verrouillé',
    Miscellaneous: 'Divers',
		ComingSoon: 'Arrive bientôt',
		Error: 'Erreur',
		404: '404',
		500: '500',
		NotAuthorized: 'Pas autorisé',
		Maintenance: 'Entretien',
		Profile: 'Profil',
		Invoice: 'Facture d\'achat',
		FAQ: 'FAQ',
		Search: 'Chercher',
		KnowledgeBase: 'Base de connaissances',
		ChartsAndMaps: 'Graphiques Et Cartes',
		Charts: 'Graphiques',
		ApexCharts: 'Apex Charts',
		chartjs: 'chartjs',
		echarts: 'echarts',
		chartist: 'chartist',
		GoogleMap: 'Google Carte',
		Extensions: 'Extensions',
		QuillEditor: 'Éditeur de piquants',
		DragAndDrop: 'Drag & Drop',
		Datepicker: 'Sélecteur de date',
    DatetimePicker: 'Sélecteur de date / heure',
		AccessControl: `Contrôle d'accès`,
		EditorView: `Vue de l'éditeur`,
		AdminView: 'Vue administrateur',
		I18n: 'I18n',
		Carousel: 'Carrousel',
		Clipboard: 'Presse-papiers',
		ContextMenu: 'Menu contextuel',
    StarRatings: 'Classement par étoiles',
    Autocomplete: 'Terminé automatiquement',
    Tree: 'Arbre',
		Others: 'Autre',
		MenuLevels: 'Niveaux de menu',
		MenuLevel2p1: 'Niveau de menu 2.1',
		MenuLevel2p2: 'Niveau de menu 2.2',
		MenuLevel3p1: 'Niveau de menu 3.1',
		MenuLevel3p2: 'Niveau de menu 3.2',
		DisabledMenu: 'Menu désactivé',
		Support: 'Soutien',
		Documentation: 'Documentation',
		RaiseSupport: 'Augmenter le soutien',
		demoTitle: 'Titre de la carte',
		demoText: `Le gâteau au sésame s'enclenche au petit pain au pain d'épices danois J'adore le pain d'épices. Tarte aux pommes et jujubes chupa chups muffin halvah sucette. Tiramisu gâteau au chocolat gâteau d'avoine tiramisu prune sucre. Donut tarte sucrée gâteau à l'avoine dragée gouttes de fruits gâteau au citron en barbe à papa.`,
	},
	pt: {
		Dashboard: 'painel de controle',
		eCommerce: 'comércio eletrônico',
		Apps: 'APPS',
		Todo: 'Façam',
		Chat: 'Bate-papo',
		Email: 'O email',
		Calendar: 'Calendário',
    FullCalendar: 'Calendário Completo',
    SimpleCalendar: 'Calendário Simples',
		Shop: 'fazer compras',
		Cart: 'Carrinho',
    WishList: 'Lista de Desejos',
		Checkout: 'Confira',
		UIElements: 'UI Elementos',
		DataList: 'Lista de dados',
		ListView: 'Exibição de lista',
		ThumbView: 'Thumb View',
		Grid: 'Grade',
		Vuesax: 'Vuesax',
		Tailwind: 'Tailwind',
		Colors: 'Cores',
		Card: 'Cartão',
		Basic: 'Basic',
		Statistics: 'Estatisticas',
		Analytics: 'Analytics',
		CardActions: 'Ações do Cartão',
    CardColors: 'Cores do cartão',
		Table: 'Mesa',
    agGridTable: 'Tabela AgGrid',
		Components: 'Componentes',
		Alert: 'Alerta',
		Avatar: 'Avatar',
		Badge: 'Crachá',
		Breadcrumb: 'Breadcrumb',
		Button: 'Botão',
    ButtonGroup: 'Grupo de botões',
		Chip: 'Lasca',
		Collapse: 'Colapso',
		Dialogs: 'Diálogos',
		Divider: 'Divisor',
		DropDown: 'Suspenso',
		List: 'Lista',
		Loading: 'Carregando',
		Navbar: 'Navbar',
		Notifications: 'Notificações',
		Pagination: 'Paginação',
		Popup: 'Aparecer',
		Progress: 'Progresso',
		Sidebar: 'Barra Lateral',
		Slider: 'Controle Deslizante',
		Tabs: 'Guias',
		Tooltip: 'dica de ferramenta',
		Upload: 'Envio',
		FormsAndTable: 'Formulários e tabela',
		FormElements: 'Elementos do formulário',
		Select: 'Selecione',
		Switch: 'Interruptor',
		Checkbox: 'Caixa de seleção',
		Radio: 'Rádio',
		Input: 'Entrada',
		NumberInput: 'Entrada numérica',
		Textarea: 'Textarea',
		FormLayouts: 'Layouts de formulário',
		FormWizard: 'Assistente de Formulário',
		FormValidation: 'Validação de Formulário',
    FormInputGroup: 'Grupo de entrada de formulário',
		Pages: 'PÁGINAS',
		Authentication: 'Autenticação',
		Login: 'Entrar',
		Register: 'Registo',
		ForgotPassword: 'Esqueceu A Senha',
		ResetPassword: 'Redefinir Senha',
		LockScreen: 'Tela de bloqueio',
    Miscellaneous: 'Diversos',
		ComingSoon: 'Em breve',
		Error: 'Erro',
		404: '404',
		500: '500',
		NotAuthorized: 'Não autorizado',
		Maintenance: 'Manutenção',
		Profile: 'Perfil',
		Invoice: 'Fatura',
		FAQ: 'Perguntas frequentes',
		Search: 'Procurar',
		KnowledgeBase: 'Base de Conhecimento',
		ChartsAndMaps: 'Gráficos E Mapas',
		Charts: 'Gráficos',
		ApexCharts: 'Apex Charts',
		chartjs: 'chartjs',
		echarts: 'echarts',
		chartist: 'chartist',
		GoogleMap: 'Google Mapa',
		Extensions: 'Extensão',
		QuillEditor: 'Quill-Editor',
		DragAndDrop: 'Arraste e solte',
		Datepicker: 'Datepicker',
    DatetimePicker: 'Seletor de data e hora',
		AccessControl: 'Controle de acesso',
		EditorView: 'Editor View',
		AdminView: 'Vista de administração',
		I18n: 'I18n',
		Carousel: 'Carrossel',
		Clipboard: 'Prancheta',
		ContextMenu: 'Menu contextual',
    StarRatings: 'Classificações por estrelas',
    Autocomplete: 'autocompletar',
    Tree: 'Árvore',
		Others: 'Outras',
		MenuLevels: 'Níveis de Menu',
		MenuLevel2p1: 'Nível de menu 2.1',
		MenuLevel2p2: 'Nível de menu 2.2',
		MenuLevel3p1: 'Nível de menu 3.1',
		MenuLevel3p2: 'Nível de menu 3.2',
		DisabledMenu: 'Menu desativado',
		Support: 'Apoio, suporte',
		Documentation: 'Documentação',
		RaiseSupport: 'Levantar Suporte',
		demoTitle: 'Título do cartão',
		demoText: `O sésamo do bolo agarra dinamarquês do pão-de-espécie do queque eu amo o pão-de-espécie. Torta de torta de maçã jujuba chupa chups muffin halvah pirulito. Ameixa do açúcar do maçapão do tiramisu do bolo da aveia do bolo de chocolate. Bolo de aveia de torta doce rosquinha dragée fruitcake algodão doce gotas de limão.`,
	}
}
