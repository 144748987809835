export function BASEURL() {
  let params = parseQueryString();
  let emailExp = params["d"];
  let url = emailExp
    ? "https://dharma" + emailExp + ".gokozo.com"
    : localStorage.getItem("BASEURL")
    ? localStorage.getItem("BASEURL")
    : "https://masterapi.gokozo.com";

  return url;
}
function parseQueryString() {
  var str = window.location.search;
  var objURL = {};

  str.replace(
    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
    function ($0, $1, $2, $3) {
      objURL[$1] = $3;
    }
  );
  return objURL;
}
