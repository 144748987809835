/*=========================================================================================
  File Name: contacts.js
  Description: Contacts neeeded for chat application
  Strucutre:
      id 
      name
      status
      img
      iconStatus - active, offline, do not disturb, Away
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/



export default [
  {
    id: 1,
    name: 'Felecia Rower',
    about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
    img: 'avatar-s-1.png',
    status: 'offline'
  },
  {
    id: 2,
    name: 'Adalberto Granzin',
    about: 'Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
    img: 'avatar-s-2.png',
    status: 'do not disturb'
  },
  {
    id: 3,
    name: 'Joaquina Weisenborn',
    about: 'Soufflé soufflé caramels sweet roll. Jelly lollipop sesame snaps bear claw jelly beans sugar plum sugar plum.',
    img: 'avatar-s-3.png',
    status: 'do not disturb'
  },
  {
    id: 4,
    name: 'Verla Morgano',
    about: 'Chupa chups candy canes chocolate bar marshmallow liquorice muffin. Lemon drops oat cake tart liquorice tart cookie. Jelly-o cookie tootsie roll halvah.',
    img: 'avatar-s-4.png',
    status: 'online'
  },
  {
    id: 5,
    name: 'Margot Henschke',
    about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
    img: 'avatar-s-5.png',
    status: 'do not disturb'
  },
  {
    id: 6,
    name: 'Sal Piggee',
    about: 'Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
    img: 'avatar-s-6.png',
    status: 'online'
  },
  {
    id: 7,
    name: 'Miguel Guelff',
    about: 'Biscuit powder oat cake donut brownie ice cream I love soufflé. I love tootsie roll I love powder tootsie roll.',
    img: 'avatar-s-7.png',
    status: 'online'
  },
  {
    id: 8,
    name: 'Mauro Elenbaas',
    about: 'Bear claw ice cream lollipop gingerbread carrot cake. Brownie gummi bears chocolate muffin croissant jelly I love marzipan wafer.',
    img: 'avatar-s-8.png',
    status: 'away'
  },
  {
    id: 9,
    name: 'Bridgett Omohundro',
    about: 'Gummies gummi bears I love candy icing apple pie I love marzipan bear claw. I love tart biscuit I love candy canes pudding chupa chups liquorice croissant.',
    img: 'avatar-s-9.png',
    status: 'offline'
  },
  {
    id: 10,
    name: 'Zenia Jacobs',
    about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
    img: 'avatar-s-10.png',
    status: 'away'
  },
]