/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";
import { LanguageService, TokenService } from "@/services/storage.service.js";
import UserService from "@/services/UserService.js";

// // Firebase
// import * as firebase from 'firebase/app';
// import '@firebase/messaging';

// var firebaseConfig = {
//   apiKey: 'AIzaSyCiOZnvmfsCGsxpkBJPK5Al_UTYxot29nA',
//   authDomain: 'go-kozo.firebaseapp.com',
//   databaseURL: 'https://go-kozo.firebaseio.com',
//   projectId: 'go-kozo',
//   storageBucket: 'go-kozo.appspot.com',
//   messagingSenderId: '1069645846300',
//   appId: '1:1069645846300:web:9239f16bb89e461d43ccd9',
//   measurementId: 'G-761JKKBSR5',
// };
// // initialize Firebase
// firebase.initializeApp(firebaseConfig);
// const messaging = firebase.messaging();

// // Add the public key generated from the console here.
// // messaging.usePublicVapidKey("xxxxxxxxxxx");

// messaging.requestPermission().then(() => {
//   console.log('Notification permission granted.');
//   messaging.getToken().then((token) => {
//     console.log("messaging get token:>>>>>", token);
//   })
// }).catch((err) => {
//   console.log('Unable to get permission to notify.', err);
// });

// messaging
//   .requestPermission()
//   .then(() => firebase.messaging().getToken())
//   .then(token => {
//     console.log('token firebase :', token);

//     // notificationService.sendFirebaseToken({ firebase_token: token }).then(response => {
//     //   console.log('response firebase :', response);
//     //   let res = response.data;
//     //   if (!res.error && res && res.statusCode == '200' && res.data.length > 0) {
//     //   } else {
//     //     // Show Error Message, Whcih is comming from backend5
//     //   }
//     // });
//   })
//   .catch(function (err) {
//     console.log('Unable to get permission to notify.', err);
//   });

// register globally
Vue.component("multiselect", Multiselect);

Vue.use(Vuesax);

Vue.use(BootstrapVue);

// axios
import axios from "axios";
Vue.prototype.$http = axios;

// Theme Configurations
import "../themeConfig.js";

// Algolia - Instant Search
import InstantSearch from "vue-instantsearch";
Vue.use(InstantSearch);

// Auth0 Plugin
import AuthPlugin from "./plugins/auth";
Vue.use(AuthPlugin);

// ACL
import acl from "./acl/acl";
import permissions from "./acl/permissions";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// i18n
import i18n from "./i18n/i18n";

// Vuesax Admin Filters
import "./filters/filters";

// Clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// Tour
import VueTour from "vue-tour";
Vue.use(VueTour);
require("vue-tour/dist/vue-tour.css");

// VeeValidate
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
//Focus Lock
import FocusLock from "@/components/FocusLock.vue";
Vue.component("FocusLock", FocusLock);

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

// Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: "API_KEY",
    libraries: "places" // This is required if you use the Auto complete plug-in
  }
});

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
require("./assets/css/iconfont.css");
import { checkPermission, checkSideNavPermission } from "@/plugins/Permissions";

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

global.vueMain = new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount("#app");

// Created the Event Bus for the call parent function
global.eventBus = new Vue();
// global.permissions = permissions;
// let langToken = LanguageService.getLanguage() || {
//   lang: 'en',
//   lang_id: 2,
// };
// let country = CountryService.getCountry();
// let countryName = country && country['CountryName'].toLowerCase() ? country['CountryName'].toLowerCase() : 'uae';
Vue.mixin({
  data: function() {
    return {
      data_loading: false,
      temp_token: window.localStorage.getItem("TempToken"),
      access_token: window.localStorage.getItem("access_token"),
      permissions: [],
      allLanguageData: "en",
      countryName: "uae", // countryName,
      LOADING: null,
      secureStore: {
        set: function(key, value) {
          let setEncryptedData = CryptoJS.AES.encrypt(
            JSON.stringify(value),
            secretKey
          ).toString();
          window.localStorage.setItem(key, setEncryptedData);
        },
        get: function(key) {
          let getEncryptedData = window.localStorage.getItem(key);
          if (!_.isNull(getEncryptedData))
            return JSON.parse(
              CryptoJS.AES.decrypt(getEncryptedData, secretKey).toString(
                CryptoJS.enc.Utf8
              )
            );
          return null;
        },
        remove: function(key) {
          window.localStorage.removeItem(key);
        }
      },
      sessionStore: {
        set: function(key, value) {
          let setEncryptedData = CryptoJS.AES.encrypt(
            JSON.stringify(value),
            secretKey
          ).toString();
          window.sessionStorage.setItem(key, setEncryptedData);
        },
        get: function(key) {
          let getEncryptedData = window.sessionStorage.getItem(key);
          if (!_.isNull(getEncryptedData))
            return JSON.parse(
              CryptoJS.AES.decrypt(getEncryptedData, secretKey).toString(
                CryptoJS.enc.Utf8
              )
            );
          return null;
        },
        remove: function(key) {
          window.sessionStorage.removeItem(key);
        }
      }
    };
  },

  methods: {
    checkPermission: ([module_name, method]) => {
      return checkPermission([module_name, method]);
    },
    checkSideNavPermission: ([module_name]) => {
      return checkSideNavPermission([module_name]);
    },
    showLoading: function() {
      this.LOADING = this.$loading.show({
        color: "#e0007c",
        // color: '#AD2318',

        loader: "spinner",
        width: 105,
        height: 105,
        class: "css-loader",
        backgroundColor: "rgba(0, 0, 0, 0.5);",
        opacity: 0.5,
        zIndex: 99999
      });
      document.body.classList.add("modal-open");
      this.data_loading = true;
    },

    hideLoading: function() {
      this.LOADING ? this.LOADING.hide() : "";
      document.body.classList.remove("modal-open");
      this.data_loading = false;
    }
  }
});
