import Api from "./Api.js";
// let organization_id = window.localStorage.getItem("OrganizationID");

const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  getAllBudget(payload) {
    console.log("Hello");
    // return Api().get(`/v2/budget/project/${payload.project_id}`);
    if (payload && payload.hasOwnProperty("page")) {
      return Api().get(
        `/v2/budget/project/${payload.project_id}?page_no=${
          payload.page
        }&organization_id=${organization_id()}`
      );
    } else {
      return Api().get(
        `/v2/budget/project/${
          payload.project_id
        }?organization_id=${organization_id()}`
      );
    }
  },

  editBudget(payload, id) {
    return Api().put(
      `/v2/budget/${id}?organization_id=${organization_id()}`,
      payload
    );
  },

  getSingleBudget(id) {
    return Api().get(`/v2/budget/${id}?organization_id=${organization_id()}`);
  },
  getBudgetLogs(id) {
    return Api().get(
      `/v2/budget/logs/${id}?organization_id=${organization_id()}`
    );
  },
  getExpenseBudgetLog(code_name, project_id) {
    return Api().get(
      `v2/budget/getExpensesByBudget/0?organization_id=${organization_id()}&budget_code=${code_name}&project_id=${project_id}`
    );
  },
  getExpenseLog(id) {
    //dev2-api.gokozo.com/v2/budget/getExpensesByBudget/{budget_id}
    https: return Api().get(
      `v2/budget/getExpensesByBudget/${id}?organization_id=${organization_id()}`
    );
  },
  getBudgetReport(id, payload) {
    return Api().get(
      `/v2/budget/code/${id}?dateFrom=${payload.from_date}&dateTo=${
        payload.to_date
      }&organization_id=${organization_id()}`
    );
  },
  getBudgetReportsList(id) {
    return Api().get(
      `/v2/budget/code/${id}?organization_id=${organization_id()}`
    );
  },
  setBudget(payload) {
    return Api().post(
      `/v2/dailybudget?organization_id=${organization_id()}`,
      payload
    );
  },
  //bydate
  getBudgetByDate(dailybudget_date) {
    return Api().get(
      `/v2/dailybudget?dailybudget_date=${dailybudget_date}&organization_id=${organization_id()}`
    );
  },
  //bydate&project
  getBudgetByProjectId(id, dailybudget_date) {
    return Api().get(
      `/v2/dailybudget?project_id=${id}&dailybudget_date=${dailybudget_date}&organization_id=${organization_id()}`
    );
  },
  addBudget(payload) {
    return Api().post(
      `/v2/budget?organization_id=${organization_id()}`,
      payload
    );
  },
  editDailybudgetBudget(payload, id) {
    return Api().put(
      `/v2/dailybudget/dailybudget_id=${id}?organization_id=${organization_id()}`,
      payload
    );
  },
  getBudgetCodes(id) {
    return Api().get(
      `/v2/budget/getBudgetCodes/${id}?organization_id=${organization_id()}`
    );
  },
  deleteBudget(id) {
    return Api().delete(`v2/budget/${id}?organization_id=${organization_id()}`);
  },
  moveToBudget(payload) {
    return Api().post(
      `v2/budget/expenses/moveToBudget?organization_id=${organization_id()}`,
      payload
    );
  }
};
