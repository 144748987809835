<template>
  <div class="dialog-focus-lock">
    <slot></slot>
  </div>
</template><script>
export default {
  name: "focus-lock",
  data() {
    return {
      focusableEls: [],
      firstFocusableEl: [],
      lastFocusableEl: [],
      focusedElBeforeOpen: document.activeElement
    };
  },
  methods: {
    handleDialogFocusLock() {
      const selectors =
        "" +
        "a[href], " +
        "input:not([disabled]), " +
        "select:not([disabled]), " +
        "textarea:not([disabled]), " +
        "button:not([disabled])";
      const getEls = this.$el.querySelectorAll(selectors);
      if (getEls && getEls.length > 0) {
        this.focusableEls = Array.prototype.slice.call(getEls);
        [this.firstFocusableEl] = this.focusableEls;
        [this.lastFocusableEl] = this.focusableEls.slice(-1);
        this.$el.addEventListener("keydown", e => this.handleKeyDown(e));
        this.firstFocusableEl.focus();
      }
    },
    handleBackwardTab(e) {
      if (document.activeElement === this.firstFocusableEl) {
        e.preventDefault();
        this.lastFocusableEl.focus();
      }
    },
    handleForwardTab(e) {
      if (document.activeElement === this.lastFocusableEl) {
        e.preventDefault();
        this.firstFocusableEl.focus();
      }
    },
    handleKeyDown(e) {
      const KEY_TAB = 9;
      switch (e.keyCode) {
        case KEY_TAB:
          if (this.focusableEls.length === 1) {
            e.preventDefault();
            break;
          }
          if (e.shiftKey) {
            this.handleBackwardTab(e);
          } else {
            this.handleForwardTab(e);
          }
          break;
        default:
          break;
      }
    }
  },
  mounted() {
    this.handleDialogFocusLock();
  }
};
</script>