/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import BudgetService from "@/services/budgetService.js";
import vue from "vue";
// import V

const actions = {
  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  updateSidebarWidth({ commit }, width) {
    commit("UPDATE_SIDEBAR_WIDTH", width);
  },
  updateI18nLocale({ commit }, locale) {
    commit("UPDATE_I18N_LOCALE", locale);
  },
  toggleContentOverlay({ commit }) {
    commit("TOGGLE_CONTENT_OVERLAY");
  },
  updateTheme({ commit }, val) {
    commit("UPDATE_THEME", val);
  },
  updateUserRole({ commit }, val) {
    commit("UPDATE_USER_ROLE", val);
  },
  updateWindowWidth({ commit }, width) {
    commit("UPDATE_WINDOW_WIDTH", width);
  },
  setPermissions({ commit }) {
    commit("SET_PERMISSIONS");
  },
  setCategoryName({ commit }, payload) {
    commit("SET_CATEGORY_NAME", payload);
  },
  setBudgetDate({ commit }, payload) {
    commit("SET_BUDGET_DATE", payload);
  },
  BudgetReportData({ commit }, payload) {
    commit("SET_BUDGET_REPORT_DATA", payload);
  },
  tabIndex({ commit }, payload) {
    commit("SET_TAB_INDEX", payload);
  },
  selectedCode({ commit }, payload) {
    commit("SET_SELECTED_CODE", payload);
  },

  SetProjectID({ commit }, payload) {
    commit("SET_CATEGORY_PROJECT_ID", payload);
  },
  SetCurrentPageBudget: async ({ dispatch, commit, state }, payload) => {
    await commit("SET_BUDGET_LIST_PAGE", payload.page);
    await dispatch("getBudgetList", {
      project_id: state.budgetObject.projectId
    });
  },
  getBudgetList: async ({ commit, state }, payload) => {
    await commit("SET_PROJECT_ID", payload.project_id);
    let requestPayload = {
      ...payload,
      page: state.budgetObject.currentPage
    };
    if (requestPayload.project_id) {
      vueMain.$vs.loading();
      BudgetService.getAllBudget(requestPayload)
        .then(async response => {
          const { data } = response;
          if (payload.project_id) {
            if (data.data.length <= 0) {
              vueMain.$vs.notify({
                title: "No budget uploaded for this project",
                iconPack: "feather",
                icon: "check_box",
                color: "warning"
              });
            }
          }

          vueMain.$vs.loading.close();
          if (data.Status == false) {
          } else {
            await commit("SET_IS_DELETE", {
              is_delete: data.isDelete
            });
            // console.log('state.budgetObject.currentPage :', state.budgetObject.currentPage);
            await commit("SET_BUDGET_LIST", {
              Records: data.data,
              pagination: data.pagination.last_page,
              ExtraField: data.ExtraField
            });
          }
        })
        .catch(error => {
          vueMain.$vs.loading.close();
          console.log(error);
        });
    } else {
      vueMain.$vs.loading.close();
    }
  },

  clearProjectID({ commit }) {
    commit("CLEAR_PROJECT_ID");
  },

  // clearBudgetList({commit, state}){
  //   commit('CLEAR_BUDGET_LIST')
  // },

  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit("UPDATE_STARRED_PAGE", payload);
  },

  //  The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_LIMITED", list);
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_MORE", list);
  },

  setExpenseFilter({ commit }, list) {
    commit("SET_EXPENSE_FILTER", list);
  }
};

export default actions;
