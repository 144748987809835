import Vue from "vue";
import UserService from "@/services/UserService.js";
import { async } from "q";

export const permissions = async () => {
  if (Vue.permissions === undefined || Vue.permissions.length === 0) {
    const result = await setPermissions();
    return result;
  } else return Vue.permissions;
};

export const checkPermission = ([module_name, method]) => {
  // if (Vue.permissions === undefined || Vue.permissions.length === 0) {
  //     const result = await setPermissions();
  //     return result.filter(x => x.module_name == module_name && method == x.method_name && x.permission_active == 2).length > 0;
  // } else
  return (
    !Vue.permissions.filter(
      x =>
        x.module_name == module_name &&
        method == x.method_name &&
        x.permission_active == 2
    ).length > 0
  );
};

export const checkSideNavPermission = ([module_name]) => {
  // if (Vue.permissions === undefined || Vue.permissions.length === 0) {
  //     const result = await setPermissions();
  //     return result.filter(x => x.module_name == module_name.i18n && x.permission_active == 2).length > 0;
  // } else
  // return !Vue.permissions.filter(x => x.module_name == module_name.i18n && x.permission_active == 2).length > 0
  if (
    module_name.name == "Projects" &&
    Vue.permissions.filter(
      x =>
        x.module_name == "project" &&
        x.method_name == "GET" &&
        x.permission_active == 2
    ).length > 0
  ) {
    return false;
  } else if (
    module_name.name == "Team" &&
    Vue.permissions.filter(
      x =>
        x.module_name == "users" &&
        x.method_name == "GET" &&
        x.permission_active == 2
    ).length > 0
  ) {
    return false;
  } else if (
    module_name.name == "User Role" &&
    Vue.permissions.filter(
      x =>
        x.module_name == "userrole" &&
        x.method_name == "GET" &&
        x.permission_active == 2
    ).length > 0
  ) {
    return false;
  } else if (
    module_name.name == "Expenses" &&
    Vue.permissions.filter(
      x =>
        x.module_name == "expenses" &&
        x.method_name == "GET" &&
        x.permission_active == 2
    ).length > 0
  ) {
    return false;
  } else {
    return true;
  }
};

export const setPermissions = () => {
  Vue.permissions = [];
  new Promise((resolve, reject) => {
    UserService.getProfileInfo()
      .then(response => {
        console.log(response, "fsdfdsfdsfdsfdsf");
        const { data } = response;
        let checkUserType = data.data.checkUserType;

        if (
          localStorage.getItem("UserType") != data.data.checkUserType ||
          localStorage.getItem("UserType") == undefined ||
          localStorage.getItem("isAdvanced") != data.data.advance_isActive ||
          localStorage.getItem("isCreditNote") !=
            data.data.creditNote_isActive ||
          localStorage.getItem("isTally") != data.data.tally_isActive
        ) {
          //
          localStorage.setItem("UserType", checkUserType);
          localStorage.setItem("isAdvanced", data.data.advance_isActive);
          localStorage.setItem("isCreditNote", data.data.creditNote_isActive);
          localStorage.setItem("isShootLocation", data.data.isShootLocation);
          localStorage.setItem("isTally", data.data.tally_isActive);
          localStorage.setItem("digiSign", data.data.digiSign_isActive);

          window.location.reload(true);
        }
        if (data && data.data.permission.length > 0) {
          Vue.permissions = data.data.permission;
          // console.log('Vue.permissions :', Vue.permissions);
          resolve(data.data.permission);
        }
      })
      .catch(err => {});
  });
};
