import axios from "axios";
import Vue from "vue";
import { BASEURL } from "@/config/index";
import { TokenService, ProfileService } from "@/services/storage.service.js";

export default () => {
  const instance = axios.create({
    baseURL: BASEURL()
  });
  // let token = window.localStorage.getItem("access_token");
  // if (token) {
  if (TokenService.getToken()) {
    instance.defaults.headers.common["authorization"] = TokenService.getToken();
  } else {
    delete instance.defaults.headers.common["authorization"];
  }
  // }
  instance.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response.status == 403 || error.response.Status == 403) {
        vueMain.$vs.notify({
          title: "You do not have access, please talk to the administrator",
          text: error.message,
          iconPack: "feather",
          icon: "check_box",
          color: "error"
        });
      } else if (error.response.status == 500) {
        vueMain.$vs.notify({
          title: "Something went wrong!",
          text: error.message,
          iconPack: "feather",
          icon: "check_box",
          color: "error"
        });
      } else if (error.response.status == 401) {
        TokenService.removeToken();
        ProfileService.removeProfile();
        localStorage.removeItem("UserName");
        localStorage.removeItem("TempToken");
        localStorage.removeItem("UserRole");
        vueMain.$router.push("/login");
        // location.reload();
      } else {
        return Promise.reject(error);
      }
    }
  );
  return instance;
};
